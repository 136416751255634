

















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, maxLength, requiredIf, ValidationRule } from 'vuelidate/lib/validators'
import WhiteSellerController from '@/app/ui/controllers/WhiteSellerController'
import POSController from '@/app/ui/controllers/POSController'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import MarketplaceTextInput from '../../components/MarketplaceTextInput/index.vue'
import MarketplaceDropdown from '../../components/MarketplaceDropdown/index.vue'
import ModalConfirm from '../../components/MarketplaceModal/ModalConfirm.vue'
import ModalAlreadyRegister from '../../components/MarketplaceModal/ModalAlreadyRegister.vue'
import ModalSuccess from '../../components/MarketplaceModal/ModalSuccess.vue'
import ModalCourierNotFound from '../../components/MarketplaceModal/ModalCourierNotFound.vue'
import { POS } from '@/domain/entities/POS'
import { LocalStorage, Utils } from '@/app/infrastructures/misc'
import { Courier } from '@/domain/entities/Courier'
import { MAX_NAME_WHITE_SELLER, MAX_PHONE_NUMBER_WHITE_SELLER } from '@/app/infrastructures/misc/Constants/validation'
import MarketplaceInputPhone from '../../components/MarketplaceInputPhone/index.vue'

interface OptionsString {
  label: string
  value: string
}

interface OptionsNumber {
  label: string
  value: number
}

interface IValidations {
  form: {
    sellerName: {
      required: () => ValidationRule
      maxLength: ValidationRule
    }
    sellerPhone: {
      required: () => ValidationRule
      maxLength: () => boolean,
      minLength: () => boolean
    }
    status: {
      required: () => ValidationRule
    }
    agentId: {
      required: ValidationRule
    }
    sellerRole: {
      required: () => ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    DropdownSelect,
    MarketplaceTextInput,
    MarketplaceDropdown,
    ModalConfirm,
    ModalAlreadyRegister,
    ModalSuccess,
    ModalCourierNotFound,
    MarketplaceInputPhone 
  },
})
export default class AddSellerWhitelist extends Vue {
  WhiteSellerController = WhiteSellerController
  POSController = POSController
  modalCreateConfirm = false
  modalAlreadyRegister = false
  modalSuccessAdd = false
  modalCRRNFD = false
  statusOptions = [
    { label: 'Aktif', value: 'ACTIVE' },
    { label: 'Tidak Aktif', value: 'INACTIVE' },
  ]
  sellerRoleOptions = [
    { label: 'TOKOPEDIA', value: 'TOKOPEDIA' },
    { label: 'BUKALAPAK', value: 'BUKALAPAK' },
    { label: 'MERCHANT', value: 'MERCHANT' },
    { label: 'CLIENT', value: 'CLIENT' },
  ]
  codHandlingOptions = [
    {
      label: 'specialcod',
      value: 'specialcod'
    },
    {
      label: 'standardcod',
      value: 'standardcod'
    }
  ]
  partnerPOSOptions = <OptionsNumber[]>([])
  form = {
    sellerName: '',
    sellerPhone: '',
    status: this.statusOptions[0],
    agentId: [] as Record<string, any>,
    sellerRole: [] as Record<string, any>,
    codHandling: [] as Record<string, any>,
    phoneCode: '+62'
  }
  parameters = {
    page: 1,
    per_page: 100,
    search: '',
    sort_by: 'ASC',
  }
  partnerPOSRequired = true
  MAX_PHONE_NUMBER_WHITE_SELLER = MAX_PHONE_NUMBER_WHITE_SELLER
  MAX_NAME_WHITE_SELLER = MAX_NAME_WHITE_SELLER

  created(): void {
    POSController.getPOSData(this.parameters)
  }

  get myAccessMenus(): string[] {
    return JSON.parse(
      LocalStorage.getLocalStorage(LocalStorage.ACCESS_MENU, true)
    )
  }

  get showCODHandlingDropdown(): boolean {
    return this.form.sellerRole.value === 'CLIENT'
  }

  private onSelectStatus(status: OptionsString) {
    if (status.value === 'ACTIVE') {
      this.partnerPOSRequired = true
    } else {
      this.partnerPOSRequired = false
    }
  }

  private onSearchPartnerPOS = Utils.debounce((keyword: string) => {
    const params = {
      ...this.parameters,
      search: keyword
    }
    POSController.getPOSData(params)
  }, 500)

  private onSelectPartnerPOS(pos: OptionsNumber) {
    if (pos) POSController.getCourierList(String(pos.value))
  }

  private handleNewCourier() {
    if (this.myAccessMenus && this.myAccessMenus.includes("manage-courier")) {
      let routeData = this.$router.resolve({ name: 'CreateManageCourierPage' })
      window.open(routeData.href, '_blank')
    } else {
      this.$notify({
        title: 'Forbidden Access',
        text: 'Please request access to admin',
        type: 'error',
        duration: 3000,
      })
      this.modalCRRNFD = false
    }
  }

  private onBackToList() {
    this.$router.push({ name: 'ManageWhitelist' })
  }

  private onSubmit() {
    if (this.form.status && this.form.sellerRole) {
      const payload = {
        sellerName: this.form.sellerName,
        sellerPhone: `${this.form.phoneCode}${this.form.sellerPhone}`,
        status: this.form.status.value,
        agentId: this.form.agentId.value && [this.form.agentId.value],
        sellerRole: this.form.sellerRole.value,
        codHandling: this.form.codHandling?.value
      }

      if (this.form.sellerRole.value !== 'CLIENT') {
        delete payload.codHandling
      }

      WhiteSellerController.create(payload)
    }
  }

  private onPhoneCodeChange(phoneCode: string) {
    this.form.phoneCode = phoneCode
  }

  private maxLengthIndonesianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.sellerPhone.length <= 13
    }
    return true
  }

  private minLengthIndosianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.sellerPhone.length >= 9
    }
    return true
  }

  @Validations()
  validations(): IValidations {
    const isAgentIdRequired = this.partnerPOSRequired
    return {
      form: {
        sellerName: {
          required,
          maxLength: maxLength(MAX_NAME_WHITE_SELLER)
        },
        sellerPhone: {
          required,
          maxLength: this.maxLengthIndonesianPhoneNumber,
          minLength: this.minLengthIndosianPhoneNumber
        },
        status: { required },
        agentId: {
          required: requiredIf(function() {
            return isAgentIdRequired
          })
        },
        sellerRole: { required },
      },
    }
  }

  @Watch('POSController.posData')
  setPOSPartner(data: POS[]): void {
    const POSOptions = data.map(item => {
      return {
        label: `${item.name} [${item.code}]`,
        value: item.id
      }
    }) as OptionsNumber[]
    this.partnerPOSOptions = POSOptions
  }

  @Watch('POSController.posCourier')
  setPOSCourier(data: Courier[]): void {
    if (data && data.length === 0) {
      this.form.agentId = []
      this.modalCRRNFD = true
    }
  }

  @Watch('WhiteSellerController.isAddWhiteSellerSuccess')
  setAddWhiteSellerSuccess(data: boolean): void {
    this.modalCreateConfirm = false
    this.modalSuccessAdd = data
    if (!data) this.modalAlreadyRegister = true
  }

  @Watch('form.sellerName')
  setSellerName(data: string): void {
    // remove script tag
    this.form.sellerName = Utils.removeScriptTags(data)

    // remove special character
    this.form.sellerName = Utils.removeSpecialCharacters(this.form.sellerName)

    // remove html tag
    this.form.sellerName = Utils.removeHTMLTags(this.form.sellerName)
  }

  beforeDestroy(): void {
    WhiteSellerController.setIsAddWhiteSellerSuccess(false)
  }
}
